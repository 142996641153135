import React from 'react';
import Navbar from '../pages/Navbar/Navbar';
import Footer from '../pages/Footer/Footer';

const Client = ({ darkMode }) => {
  const clients = [
    // { src: "/images/tcs.jpg", alt: "TCS" },
    // { src: "/images/Flipkart-Emblem.jpeg", alt: "Flipkart" },
    // { src: "/images/n.jpg", alt: "Client N" },
    // { src: "/images/d.jpeg", alt: "Client D" },
    // { src: "/images/unnamed.jpg", alt: "Unnamed Client" },
    { src: "/images/p.jpeg", alt: "Client P" },
    // { src: "/images/tataLogo.jpeg", alt: "Tata" },
    // { src: "/images/Larsen-Toubro-logo-01.png", alt: "L&T" },
    { src: "/images/l.png", alt: "Client L" },
    { src: "/images/sp.jpeg", alt: "SP" },
    // { src: "/images/Colgate-Logo.png", alt: "Colgate" },
  ];

  return (
    <>
      <Navbar  darkMode={darkMode}/>
      <div className={`relative w-full h-[50vh] sm:h-[40vh] md:h-[60vh] `}>
        <img
          src="/images/earth_about_us.webp"
          alt="about us image"
          className="w-full h-full object-cover"
        />
        <h4 className={`absolute text-[5vh] sm:text-[4vh] md:text-[6vh] font-bold ${darkMode ? '' : ''} top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
            Client
        </h4>
      </div>

      <div className={`py-8 ${darkMode ? '' : 'bg-gray-100'}`}>
        <div className="flex flex-wrap justify-center">
          {clients.map((client, index) => (
            <div key={index} className={`bg-white shadow-lg rounded-lg p-4 m-4 max-w-xs ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
              <img
                src={client.src}
                alt={client.alt}
                className="w-full h-32 object-contain"
              />
            </div>
          ))}
        </div>
      </div>

      <Footer  darkMode={darkMode}/>
    </>
  );
};

export default Client;
