import React, { useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const AboutUs = ({ darkMode }) => {
  const countersRef = useRef([]);
  const strengthsRef = useRef(null);
  const logosRef = useRef(null);

  useEffect(() => {
    // ScrollTrigger for Counter Animation
    const countersAnimation = () => {
      countersRef.current.forEach((counter) => {
        const target = parseInt(counter.getAttribute("data-target"));
        gsap.to(counter, {
          innerText: target,
          snap: { innerText: 1 },
          duration: 3,
          ease: "power1.out",
          onUpdate: function () {
            counter.innerText = Math.floor(counter.innerText);
          },
          scrollTrigger: {
            trigger: counter,
            start: "top 80%",
            toggleActions: "play none none none",
          },
        });
      });
    };

    // Execute the counter animation
    countersAnimation();

    // Scroll animation for Strengths section
    if (strengthsRef.current) {
      gsap.fromTo(
        strengthsRef.current.children,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power1.out",
          scrollTrigger: {
            trigger: strengthsRef.current,
            start: "top 80%",
            toggleActions: "play none none none",
          },
        }
      );
    }

    // Infinite loop animation for logos
    if (logosRef.current) {
      const logoElements = logosRef.current.children;
      const totalWidth = Array.from(logoElements).reduce(
        (acc, child) => acc + child.offsetWidth,
        0
      );

      gsap.to(logosRef.current, {
        x: `-=${totalWidth}`,
        ease: "none",
        duration: 30,
        repeat: -1, // Infinite loop
        modifiers: {
          x: (x) => `${parseFloat(x) % totalWidth}px`,
        },
      });
    }
  }, []);

  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <Navbar darkMode={darkMode} />
      {/* Hero Section */}
      <div className="relative w-full h-[50vh] sm:h-[40vh] md:h-[60vh]">
        <img
          src="/images/earth_about_us.webp"
          alt="about us image"
          className="w-full h-full object-cover"
        />
        <h4 className="absolute text-[5vh] sm:text-[4vh] md:text-[6vh] font-bold text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          About us
        </h4>
      </div>

      <div className="flex flex-col md:flex-row items-center md:items-start">
        <video
          muted
          autoPlay
          loop
          src="/video/c8ad2ada6b4c8a6a6306cd381835498d.mp4"
          className="w-full md:w-[60vh] object-cover mt-4 md:ml-12"
        />

        <div className="w-full md:w-[100vh] md:ml-12 mt-8 text-lg text-center md:text-left">
          <h4 className={darkMode ? "text-white" : "text-gray-600"}>
            Our mission is to provide reliable, efficient, and effective
            services with the utmost professionalism, aiming to create
            sustainable value for all stakeholders while continuously enhancing
            the performance and efficiency of our staff and organization. We are
            committed to delivering work that satisfies our customers and earns
            public recognition. Our vision is to become the leading Electrical
            and Plumbing company in India, known for excellence and innovation.
            We are guided by core values of integrity, ensuring honesty in all
            dealings, customer satisfaction through consistent service quality,
            mutual respect for clients, employees, and partners, teamwork to
            achieve optimal results, and innovation & creativity to continually
            improve our services.
          </h4>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="px-6 py-12 lg:py-20 lg:px-24 overflow-x-hidden">
        <div className="flex flex-col md:flex-row">
          <div className="mb-16 flex-1">
            <h4 className="text-3xl font-bold text-transparent bg-clip-text bg-custom-gradient">
              Who We Are
            </h4>
            <p
              className={`text-lg ${
                darkMode ? "text-gray-300" : "text-gray-600"
              } mt-4`}
            >
              We are a trusted provider of reliable, efficient, and professional
              services in the fields of Electrical and Plumbing. Our team is
              dedicated to ensuring customer satisfaction by consistently
              delivering high-quality solutions that meet both client
              expectations and public standards. We are driven by a commitment
              to excellence, sustainability, and continuous improvement,
              striving to create value for all stakeholders while enhancing the
              effectiveness of our organization and staff.
            </p>

            <hr className="border-orange-500 my-12" />

            <div className="mt-10 mb-16">
              <h4 className="text-3xl font-bold text-transparent bg-clip-text bg-custom-gradient">
                What We Do
              </h4>
              <p
                className={`text-lg ${
                  darkMode ? "text-gray-300" : "text-gray-600"
                } mt-4`}
              >
                We specialize in providing comprehensive Electrical and Plumbing
                services across India. Our operations are focused on delivering
                results that satisfy customers and achieve public recognition.
                With a keen eye on innovation and teamwork, we ensure that all
                our projects are completed with the utmost professionalism,
                meeting the needs of our clients while contributing to a
                sustainable and efficient future.
              </p>
            </div>
          </div>

          <div className="flex-shrink-0 h-96">
            <img
              src="/images/right.jpg"
              className="w-full md:w-[60vh] mx-auto"
              alt="About info"
            />
          </div>
        </div>

        {/* Assets Section */}
        <div className="mb-16">
          <h4 className="text-3xl font-bold text-pink-600">Assets</h4>
          <hr className="border-pink-500 my-6" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            {["2024", "10", "100", "10"].map((target, idx) => (
              <div key={idx} className="bg-gray-100 p-8 rounded-lg shadow-lg">
                <h5
                  className="text-4xl font-bold text-pink-600"
                  ref={(el) => (countersRef.current[idx] = el)}
                  data-target={target}
                >
                  0
                </h5>
                <p
                  className={`mt-2 ${
                    darkMode ? "text-black" : "text-gray-600"
                  }`}
                >
                  {
                    [
                      "Established",
                      "Clients",
                      "Beneficiaries of skills-based trainings",
                      "Project Done",
                    ][idx]
                  }
                </p>
              </div>
            ))}
          </div>
        </div>

  {/* Works Section */}
<div className="mb-16">
  <h4 className="text-3xl font-bold text-orange-600">Works</h4>
  <hr className="border-orange-500 my-6" />
  <div
    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8"
    ref={strengthsRef}
  >
    {[
      { title: "Electrical installation", number: 8 },
      { title: "Plumbing installation", number: 9 },
      { title: "Security System", number: 3 },
      { title: "Other Services", number: 10 },
    ].map((strength, idx) => (
      <div key={idx} className="bg-white p-8 rounded-lg shadow-md">
        <div className="text-4xl font-bold text-orange-600">{strength.number}</div>
        <p className="mt-2 font-semibold text-black">{strength.title}</p>
      </div>
    ))}
  </div>
</div>


        {/* Clients & Partners Section */}
        <div className="w-full flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
          <h4 className="text-[4vh] font-bold w-full lg:w-[40vh] h-48 pt-12">
            Building Success Together
          </h4>
          <p className="text-[#666666] text-xl w-full lg:w-[70vh] mt-6 lg:ml-16 text-center lg:text-left">
            At the core of our approach is the belief that business success is
            achieved through strong, lasting partnerships with our clients. We
            work hand-in-hand with our clients, fostering mutual respect and
            collaboration to deliver tailored solutions that meet their specific
            needs. By prioritizing customer satisfaction, we ensure that every
            project not only fulfills expectations but also contributes to
            shared growth and success. Through innovation, integrity, and
            teamwork, we build a foundation where business and success are
            achieved together, strengthening both our clients' ventures and our
            own in a sustainable and efficient manner.
          </p>
        </div>

        {/* Adjusted logo section with proper width and overflow */}
        <div className=" mt-20 relative w-full" ref={logosRef}>
          <div className="flex space-x-8 mt-4">
            {/* <img src="/images/tcs.jpg" className="h-40 w-auto" alt="TCS" /> */}
            {/* <img
              src="/images/Flipkart-Emblem.jpeg"
              className="h-40 w-auto"
              alt="Flipkart"
            /> */}
            {/* <img src="/images/n.jpg" className="h-40 w-auto" alt="Nucleus" /> */}
            {/* <img src="/images/d.jpeg" className="h-40 w-auto" alt="Dosti" />
            <img
              src="/images/unnamed.jpg"
              className="h-40 w-auto"
              alt="Dosti"
            /> */}
            <img src="/images/p.jpeg" className="h-40 w-auto" alt="Piramal" />
            {/* <img
              src="/images/tataLogo.jpeg"
              className="h-40 w-auto"
              alt="Colgate"
            /> */}
            <img src="/images/sp.jpeg" className="h-40 w-auto" alt="Tata" />
            {/* <img
              src="/images/Larsen-Toubro-logo-01.png"
              className="h-40 w-auto"
              alt="Larsen-Toubro"
            /> */}
            {/* <img src="/images/u.jpg" className="h-40 w-auto" alt="Logo 4" /> */}
            {/* <img
              src="/images/Colgate-Logo.png"
              className="h-40 w-auto"
              alt="Colgate"
            /> */}
            <img src="/images/l.png" className="h-40 w-auto" alt="Colgate" />
            {/* You can add more logos here */}
          </div>
        </div>
      </div>
      <Footer darkMode={darkMode} />
    </div>
  );
};

export default AboutUs;
