export const projectsData = [
  // {
  //   company: "Nucleus office parks",
  //   projects: [
  //     { name: "One International Center", location: "Mumbai", imageUrl: "images/1internation.png" },
  //     { name: "One World Center", location: "Mumbai", imageUrl: "images/one world.png" },
  //     { name: "One BKC", location: "Mumbai", imageUrl: "images/1bkc.png" },
  //     { name: "Prima Bay", location: "Mumbai", imageUrl: "images/prima bay.png" },
  //     { name: "Cessna Business Park", location: "Bengaluru", imageUrl: "images/cessan park.png" },
  //     { name: "Exora Business Park", location: "Bengaluru", imageUrl: "images/exora.png" },
  //     { name: "One Trade Tower", location: "Bengaluru", imageUrl: "images/trade.png" },
  //     { name: "Fintech One", location: "Ahmedabad", imageUrl: "images/Fintech One.jpg" },

  //   ],
  // },
  {
    company: "Dosti Group",
    projects: [
      { name: "Mumbra-Shill Phata", location: "Thane", imageUrl: "images/dosti_group.jpeg" },
      { name: "Bramhand", location: "Thane", imageUrl: "images/dosti_group.jpeg" },
    ],
  },
  {
    company: "Gupta Housing",
    projects: [
      { name: "Aden Court", location: "Matunga", imageUrl: "images/gupta house.jpeg" },
    ],
  },
  {
    company: "Honeywell (Flipkart)",
    projects: [
      { name: "Honeywell (Flipkart)", location: "Bhiwandi, Thane", imageUrl: "images/flipkart-bulding.jpg" },
    ],
  },
  {
    company: "Hindustan Lever",
    projects: [
      { name: "Hindustan Lever", location: "Churchgate, Mumbai", imageUrl: "images/hl-b.gif" },
    ],
  },
  {
    company: "TCS",
    projects: [
      { name: "TCS", location: "Nerul, Navi Mumbai", imageUrl: "images/tcs-office-transformed.jpeg" },
    ],
  },
  {
    company: "Other",
    projects: [
      { name: "Trinity A Wing", location: "Sector - 06", imageUrl: "images/Paras-Trinity-Sector-63-Gurgaon.webp" },
      { name: "Express Tower", location: "Nariman Point", imageUrl: "images/express towers.jpg" },
    ],
  },
  {
      company: "Lodha",
      projects: [
        { name: "Estella A Wing, Palava II Lodha", location: "Dombivli", imageUrl: "images/lodha Milano A.jpeg" },
        { name: "Estella B Wing, Palava II Lodha", location: "Dombivli", imageUrl: "images/lodha Milano A.jpeg" },
        { name: "Marvella E Wing, Palava II Lodha", location: "Dombivli", imageUrl: "images/lodha_trinity.jpg" },
        { name: "Lakeside 1 Sector 3 Lodha Palava", location: "Dombivli", imageUrl: "images/Lakeside 1 Sector 3 Lodha Palava.jpg" },
        { name: "Lakeside 2 Sector 3 Lodha Palava", location: "Dombivli", imageUrl: "images/PALAVA-transformed.jpeg" },
        { name: "Veinto Retails and Toilet Blocks at Sector 3 Lodha", location: "Dombivli", imageUrl: "images/lodha_violet_e_and_f-taloje-.avif" },
        { name: "Commercial Spaces and Buildings at Casa Rio", location: "Dombivli", imageUrl: "images/lodha_casa_rio-transformed.jpeg" },
        { name: "Lodha River Scape", location: "Dombivli", imageUrl: "images/lodha-palava.jpg" },
        { name: "Lodha Milano A", location: "Sector 06", imageUrl: "images/Lodha Milano D.jpg" },
        { name: "Lodha Milano D", location: "Sector 06", imageUrl: "images/Lodha Milano D.jpg" },
      ],
    },
    
  ];
  